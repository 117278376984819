import React, { createContext, useContext, useState } from 'react';

export const PageContext = createContext();

export const usePageContext = () => useContext(PageContext);

// eslint-disable-next-line react/prop-types
export default ({ children, initialPageName }) => {
  const [pageName, setPageName] = useState(initialPageName);
  return <PageContext.Provider value={{ pageName, setPageName }}>{children}</PageContext.Provider>;
};
